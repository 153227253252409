.chosen__button {
  background-color: #FEFFBC;
}

.hide {
  display: none;
}

.preferences__page {
  margin: 0 auto;
  width: 100%;
  max-width: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.filtered__allergen:hover,
.filtered__ingredient:hover {
  background-color: #FEFFBC;
}

.preferences__page_wrapper {
  position: relative;
}
.preferences__page_wrapper form{
  margin-top: 10px;
}
.preferences__ingredients .preference__title{
  margin-top: 28px;
}
.preferences__main_title {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  letter-spacing: 1px;
  text-align: center;
  color: #2E2E2E;
  margin-top: 40px;
  margin-bottom: 0;
  width: 100%;
}

.preferences__page {
  width: 100%;
  max-width: 384px;
  margin: 0 auto;
}

.preference__title {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #2E2E2E;
  display: flex;
  justify-content: flex-start;
  margin-top: 25px;
  margin-bottom: 7px;
  margin-right: 8px;
}
.preference__box{
  position: relative;
}

.preference__title-content {
  margin-right: 8px;
}

.preference__quantity {
  display: inline-block;
  color: #2E2E2E;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.65;
  margin-left: 5px;
}

.preference__title .icon {
  display: inline-block;
  margin-right: 8px;
  width: 24px;
  height: 24px;
}
.preference__title .icon img{
  width: 100%;
  height: 100%;
}
.preferences__allergic,
.preferences__ingredients {
  position: relative;
  width: 100%;
}

.preference__button {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  border-radius: 6px;
  text-align: center;
  color: #2E2E2E!important;
  border: 1px solid #C3C1B9;
  padding: 10px 12px;
  margin-right: 8px;
  margin-bottom: 8px;
  background: #fff;
  outline: none;
  transition: all 0.3s;
}
.btn__ingredient,
.btn__allergen{
  color: #2E2E2E!important;
}
.ingredients{
  outline: 0;
}


.chosen__button {
  background: #FEFFBC;
}

.not_choised_button {
  /* border: 1px solid #E74D73; */
  box-shadow: 1px 1px 0 rgba(231, 77, 115, 1),
  -1px -1px 0 rgba(231, 77, 115, 1),
  1px -1px 0 rgba(231, 77, 115, 1),
  -1px 1px 0 rgba(231, 77, 115, 1);
}

.preference__button:last-child {
  margin-right: 0;
}

.preference__input_wrapper {
  border: 1px solid #C3C1B9;
  border-radius: 6px;
  padding: 6px 14px 0px 14px;
}

.input__wrapper {
  position: relative;
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 6px;
}

.preference__input_wrapper button {
  background: #fff;
  position: relative;
  border: 1px solid #C3C1B9;
  border-radius: 6px;
  padding: 5px 5px 5px 33px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  margin-bottom: 8px;
  margin-right: 8px;
}

.preference__input_wrapper button:last-child {
  margin-right: 0px;
}

.preference__input_wrapper button::after{
  content: '';
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  background: url("data:image/svg+xml,%3Csvg%20width='20'%20height='20'%20viewBox='0%200%2020%2020'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3E%3Cpath%20d='M5.91666%2015.25C5.5945%2015.5722%205.07216%2015.5722%204.75%2015.25C4.42783%2014.9279%204.42783%2014.4055%204.75%2014.0834L8.83333%2010L4.75%205.91669C4.42783%205.59452%204.42783%205.07219%204.75%204.75002C5.07216%204.42785%205.5945%204.42785%205.91666%204.75002L10%208.83335L14.0833%204.75002C14.4055%204.42785%2014.9278%204.42785%2015.25%204.75002C15.5722%205.07219%2015.5722%205.59452%2015.25%205.91669L11.1667%2010L15.25%2014.0834C15.5722%2014.4055%2015.5722%2014.9279%2015.25%2015.25C14.9278%2015.5722%2014.4055%2015.5722%2014.0833%2015.25L10%2011.1667L5.91666%2015.25Z'%20fill='%232E2E2E'%2F%3E%3C%2Fsvg%3E");
  background-size: cover;
  top: 5px;
  left: 7px;
}
.cleared-value{
  width: 20px;
  height: 20px;
  background: url("data:image/svg+xml,%3Csvg%20width='20'%20height='20'%20viewBox='0%200%2020%2020'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3E%3Cpath%20d='M5.91666%2015.25C5.5945%2015.5722%205.07216%2015.5722%204.75%2015.25C4.42783%2014.9279%204.42783%2014.4055%204.75%2014.0834L8.83333%2010L4.75%205.91669C4.42783%205.59452%204.42783%205.07219%204.75%204.75002C5.07216%204.42785%205.5945%204.42785%205.91666%204.75002L10%208.83335L14.0833%204.75002C14.4055%204.42785%2014.9278%204.42785%2015.25%204.75002C15.5722%205.07219%2015.5722%205.59452%2015.25%205.91669L11.1667%2010L15.25%2014.0834C15.5722%2014.4055%2015.5722%2014.9279%2015.25%2015.25C14.9278%2015.5722%2014.4055%2015.5722%2014.0833%2015.25L10%2011.1667L5.91666%2015.25Z'%20fill='%232E2E2E'%2F%3E%3C%2Fsvg%3E");
  background-size: cover;
}

.preference__input_wrapper button.preference__search_button {
  background: #fff;
  padding: 5px;
  border: 1px solid transparent;
  margin: 0;
  margin-right: 8px;
  padding: 0;
}

.preference__search_button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.preference__input_wrapper button.preference__search_button::after {
  display: none;
}

.preference__search_input {
  border: none;
  outline: none;
  width: 100%;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  text-transform: capitalize;
}

.form {
  margin: 0 auto;
  text-align: center;
  width: 100%;
}
button{
  -webkit-tap-highlight-color: transparent;
}
.create_query {
  /*the same styles like .starting__page_button*/
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;
  border: none;
  border-radius: 100px;
  width: 80%;
  max-width: 240px;
  line-height: 60px;
  margin: 32px auto 0;
  background:var(--main-button-red);
  color: #fff;
  cursor: pointer;
  display: inline-block;
  transition: all 0.2s;
}
/* .create_query:active,
 .create_query:focus{
  background:var(--main-button-red-active);
} */
.create_query:active{
  background:var(--main-button-red-hover);
}
.create_query.disabled{
  background: var(--main-button-red-disabled);
}


.reset {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  background: transparent;
  margin: 25px auto;
  position: relative;
  border: none;
  cursor: pointer;
  border-radius: 100px;
  width: 80%;
  max-width: 240px;
  line-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 0.5s ease;
}
.reset:active {
  background: var(--reset-button-active);
}
.reset__icon {
  /* position: absolute;
  top: 8px;
  left: 8px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 24px;
  height: 24px;

}

.reset__icon > img {
  width: 100%;
  height: 100%;
}

.reset__title {
  text-decoration: underline;
  color: #000!important; 
}

.preference__input_wrapper button {
  text-transform: capitalize;
}

.preference__variables_list {
  position: relative;
  z-index: 5;
  height: 5px;
  margin-top: 5px;
  user-select: none;
}

ul.filtered__ingredient_wrapper,
ul.filtered__allergen_wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0px 4px 6px -2px #10182808;
  box-shadow: 0px 12px 16px -4px #10182814;
  z-index: 5;
  border: 1px solid #C3C1B9;
  overflow: hidden;
  max-height: 200px;
  overflow-y: visible;
}
.filtered__ingredient::first-letter,
.filtered__allergen::first-letter {
    text-transform: uppercase;
}
.filtered__ingredient_wrapper:empty,
.filtered__allergen_wrapper:empty {
  border: none;
}

.filtered__allergen,
.filtered__ingredient {
  padding-left: 15px;
  line-height: 40px;

}

.excluded {
  box-shadow: 0px 0px 0px 1px #E74D73;
  /*background-color: rgb(245, 154, 169) !important;*/
}

.unselected__dish {
  border: 1px solid #E74D73;
}

.empty__field,
.wrong__word{
  border: 1px solid #E74D73;
  border-radius: 5px;
}
.wrong__word + .preference__variables_list{
  display: none;
}

.allergies__quantity {
  display: inline-block;
  margin-bottom: 5px;
  color: #E74D73;
}

.ingredients__quantity {
  display: inline-block;
  margin-bottom: 5px;
  color: #E74D73;
}

.popup__united{
  position: fixed;
  z-index: 99999;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px 30px 15px 15px;
  border: 1px solid #C3C1B9;
  border-radius: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #fff;
  max-width: 400px;
  width: 97%;
  box-sizing: border-box;

}

.popup__close{
  position: absolute;
  top: 5px;
  right: 7px;
  height: 20px;
  width: 20px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUuOTE2NjYgMTUuMjVDNS41OTQ1IDE1LjU3MjIgNS4wNzIxNiAxNS41NzIyIDQuNzUgMTUuMjVDNC40Mjc4MyAxNC45Mjc5IDQuNDI3ODMgMTQuNDA1NSA0Ljc1IDE0LjA4MzRMOC44MzMzMyAxMEw0Ljc1IDUuOTE2NjlDNC40Mjc4MyA1LjU5NDUyIDQuNDI3ODMgNS4wNzIxOSA0Ljc1IDQuNzUwMDJDNS4wNzIxNiA0LjQyNzg1IDUuNTk0NSA0LjQyNzg1IDUuOTE2NjYgNC43NTAwMkwxMCA4LjgzMzM1TDE0LjA4MzMgNC43NTAwMkMxNC40MDU1IDQuNDI3ODUgMTQuOTI3OCA0LjQyNzg1IDE1LjI1IDQuNzUwMDJDMTUuNTcyMiA1LjA3MjE5IDE1LjU3MjIgNS41OTQ1MiAxNS4yNSA1LjkxNjY5TDExLjE2NjcgMTBMMTUuMjUgMTQuMDgzNEMxNS41NzIyIDE0LjQwNTUgMTUuNTcyMiAxNC45Mjc5IDE1LjI1IDE1LjI1QzE0LjkyNzggMTUuNTcyMiAxNC40MDU1IDE1LjU3MjIgMTQuMDgzMyAxNS4yNUwxMCAxMS4xNjY3TDUuOTE2NjYgMTUuMjVaIiBmaWxsPSIjMkUyRTJFIi8+Cjwvc3ZnPgo=);
  background-position: 50% 50%;
  background-size: 15px;
  background-repeat: no-repeat;
  outline: none;
}
.popup__text{
  display: block;
  font-size: 14px;
  line-height: 16px;
  font-family: Poppins, sans-serif;
}

.hidden {
  display: none;
}

.shown {
  display: flex;
  animation: show .5s ease-in;
}

@keyframes show {
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

.fadeOut{
  animation: fadeOut .5s ease-in;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.input__transparent::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.popup__title{
  margin: 0;
  font-size: 16px;
  font-family: Poppins, sans-serif;
  font-weight: bold;
}
.popup__united{
  background: #FEFFBC;
  color: #2E2E2E;
}
.popup__close {
  width: 20px;
  height: 20px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 50%;
}

.popup__progress_circle {

  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: transparent;

  --size: 25px;
  --half-size: calc(var(--size) / 2);
  --stroke-width: 2px;
  --radius: calc((var(--size) - var(--stroke-width)) / 2);
  --circumference: calc(var(--radius) * pi * 2);
  --dash: calc((var(--progress) * var(--circumference)) / 100);
  animation: progress-animation 3.5s linear 0s 1 forwards;
}

.popup__progress_circle circle {
  cx: var(--half-size);
  cy: var(--half-size);
  r: var(--radius);
  stroke-width: var(--stroke-width);
  fill: none;
  stroke-linecap: round;
}

.popup__progress_circle circle.bg {
  stroke: #C3C1B9;
}

.popup__progress_circle circle.fg {
  transform: rotate(-90deg);
  transform-origin: var(--half-size) var(--half-size);
  stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
  transition: stroke-dasharray 0.3s linear 0s;
  stroke: #2E2E2E;
}

@property --progress {
  syntax: "<number>";
  inherits: false;
  initial-value: 100;
}

@keyframes progress-animation {
  from {
    --progress: 100;
  }
  to {
    --progress: 0;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: none; /* Початково приховано */
  transition: all 0.5s;
}
.overlay.active{
  display: flex;
}
.header__wrapper,
.preferences__page_wrapper{
  transition: filter 0.5s;
}

.blurred{
  filter: blur(2px); 
}

.cleared-value {
  position: absolute;
  right: -4px;
  top: -2px;
  font-size: 20px;
  line-height: 1;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
}

.disabled_reset{
  pointer-events: none;
}
.disabled_reset .reset__title{
  color: #D7D5CD!important;
}
.reset .reset__icon img:nth-child(2){
  display: none;
}
.reset.disabled_reset .reset__icon img:nth-child(1){
  display: none;
}
.reset.disabled_reset .reset__icon img:nth-child(2){
  display: block;
}
.form{
  margin-top: 12px;
}
.existed-allergies__warning,
.existed-ingredients__warning{
  position: absolute;
  z-index: 4;
  bottom: -32px;
  left: 0;
  height: 30px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 12.61px;
  text-align: center;
  width: 100%;
  /* max-width: 330px; */
  color: #E74D73;
  opacity: 0;
  visibility: hidden;
  margin: 0 auto;
}

.activated {
  opacity: 1;
  pointer-events: auto;
}

.activated-allergies-warning {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s ease-in-out, visibility 0s 0s;
}

@media all and (max-width: 1280px) and (max-height: 600px){
  .create_query{
      margin-top: 20px;
  }
}
@media all and (max-width: 768px){
  /* .starting__page{
      margin-top: 77px;
  } */
}
@media all and (max-width: 500px){
  
}

@media all and (max-width: 450px){
  .starting__page{
      margin-top: 30px;
  }
  .preferences__main_title{
    margin-top: 24px;
  }
  .preference__title{
    margin-top: 18px;
  }
  .preferences__page{
    max-width: calc(100% - 18px);
  }
}

@media all and (max-width: 415px){

}

@media all and (max-width: 415px) and (max-height: 680px){
  
}

@media all and (max-width: 400px){

}

@media all and (max-width: 390px){
  .preference__title{
    font-size: 19px;
  }
}

@media all and (max-width: 376px){

}

@media all and (max-width: 360px){
  .preferences__main_title{
    font-size: 20px;
    line-height: 24px;
  }
  .preference__title .icon img{
    width: 20px;
    height: 20px;
  }
}

@media all and (max-width: 350px){
  
  .create_query{
      line-height: 50px;
      font-size: 20px;
  }
  .preference__title{
    margin-top: 18px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
  }
  .preference__button{
    font-size: 14px;
    line-height: 16px;
  }
  .existed-allergies__warning,
  .existed-ingredients__warning{
    font-size: 12px;
    line-height: 12px;
  }
}

@media all and (max-width: 320px){

}



@media only screen 
  and (max-width: 430px) 
  and (min-device-height: 812px) 
  and (max-device-height: 932px) 
  and (-webkit-min-device-pixel-ratio: 2) {
  ol{
    padding-left: 29px;
  }
  .ol {
    /* color: green; */
    padding-left: 0px; 
    list-style-position: inside;
    text-indent: -1px;
    margin-left: -1px; 
  }
  .ul::before {
    content: ''; 
    display: inline-block;
    left: -4px;
  }
  
}
@media screen and (max-height: 500px) and (orientation: landscape) and (min-width: 500px) {
  .preferences__main_title{
    margin-top: 8px;
  }
  .preference__button{
    font-size: 15px;
  }
}


