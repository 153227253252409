

.header {
  background-color: var(--background-color-all-pages);
}
  

/*menu*/
.menu__btn{
  display: block;
  width: 24px;
  height: 24px;
  position:relative;
  z-index: 11;
  cursor: pointer;
  
}
.menu__btn span,
.menu__btn span::before,
.menu__btn span::after{
  position:absolute;
  top:50%;
  width: 24px;
  height: 2.5px;
  background-color: #2E2E2E;
  margin:-1px 0px 0px -12px;
  left: 50%;
  border-radius: 5px;
}
.menu__btn span::before,
.menu__btn span::after{
  content:'';
  display:block;
  -webkit-transition:0.25s;
  -o-transition:0.25s;
  transition:0.25s;
}
.menu__btn span::before{
	-webkit-transform:translateY(-8px);
	-ms-transform:translateY(-8px);
  	transform:translateY(-8px);
}
.menu__btn span::after{
  	-webkit-transform:translateY(8px);
	-ms-transform:translateY(8px);
  	transform:translateY(8px);
}
.menu__btn_active span::before{
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
  	transform: rotate(-45deg);
  	width:30px;
}
.menu__btn_active span::after{
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	width:30px;
}
.menu__btn_active span{
	height: 0;
}


/* Початкові стилі для прихованого меню */
.menu__nav {
  overflow: hidden;
  transition: all .5s;
  opacity: 0;
  transform: translateX(100%);
  position: fixed;
  top: 30px;
  right: 0;
  z-index: 10;
  background: #fff;
  height: 100%;
  width: 0;
  visibility: hidden;
}

/* Стилі для активного меню */
.menu__nav_active {
  position: fixed;
  opacity: 1;
  width: 100%;
  min-height: 100vh;
  transform: translateX(0);
  visibility: visible;
}

/* Клас для приховування меню */
.hidden {
  
}

.menu__nav .menu__item,
.menu__nav .attempts__counter{
  width: 100%;
  margin: 10px auto 0;
  padding: 5px 0 5px 25%;
  box-sizing: border-box;
  background: #fff;
  border: none;
  transition: background-color 0.7s;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.menu__nav .menu__item:hover{
  cursor: pointer;
  background-color: #FEFFBC;
}

.menu__nav .attempts__counter{
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  color: #2E2E2E;
  width: 100%;
  padding: 5px 0px 5px 25%;
}
.attempts__counter strong{
  margin-left: 5px;
}
.menu__nav_link{
  display: inline-block;
  text-decoration:none;
  color:#90f;
  margin-right: 20px;
}

.menu__block{
  flex-direction: column;
	height: 80%;
  -webkit-transition:0.5s;
  -o-transition:0.5s;
  transition:0.5s;
  list-style: none;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 0;
}
.menu__nav span{
  display: inline-block;
  text-align: left;
  
}
.menu__title{
  text-align: center;
  margin-top: 5%;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  letter-spacing: 1px;

}
.menu-item{}

.menu__item span{
	color: #2E2E2E;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  position: relative;
}
menu{
	padding-left: 0px;
  margin: 0;
}


.menu__item{
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  border: none;
  background: none;
}
.menu__item:hover{
	text-decoration: none;
}
.current-menu-item button{
	color: #FF0075;
}
.current-menu-item button:hover{
	
}
ol,ul{
	margin: 0;
	padding: 0;
	list-style: none;
}
/*end menu*/
.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0 15px;
  max-width: 1550px;
  margin: 0 auto;
}
.header__wrapper{
  position: relative;
  z-index: 20;
  width: 100%;
  padding: 6px 0;
  box-sizing: border-box;
  border-bottom: 1px solid #C3C1B9;
}
.menu__item_icon{
  margin-right: 10px;
  display: block;
  padding-top: 5px;
  width: 24px;
  height: 34px;
}
.menu__item_icon img{
  width: 100%;
}
.logo__wrapper{
  max-width: 116px;
  margin: 2px auto -2px;
}
.logo{
  width: 100%;
}
.button_back{
  width: 30px;
  height: 30px;
  background: transparent;
  border: none;
  outline: 0;
  position: relative;
  cursor: pointer;
}
.button_back:before{
  content: '';
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuNDU5MzkgMTYuNzM4TDE1Ljg3OSAyMi45NjQzQzE2LjI4MDIgMjMuMzUzNSAxNi4yNzc0IDIzLjk4NTQgMTUuODcyNSAyNC4zNzExTDE1LjUxMDUgMjQuNzE2MUMxNS4xMTA3IDI1LjA5NyAxNC40NjkxIDI1LjA5NDMgMTQuMDcyOCAyNC43MDk5TDUuMjk5MDkgMTYuMjAwM0M0LjkwMDMgMTUuODEzNSA0LjkwMDMgMTUuMTg2NSA1LjI5OTA5IDE0Ljc5OTdMMTQuMDcyOCA2LjI5MDA4QzE0LjQ2OTEgNS45MDU3MiAxNS4xMTA3IDUuOTAyOTcgMTUuNTEwNSA2LjI4MzkxTDE1Ljg3MjUgNi42Mjg4N0MxNi4yNzc0IDcuMDE0NjIgMTYuMjgwMiA3LjY0NjQ5IDE1Ljg3OSA4LjAzNTY5TDkuNDU5MzkgMTQuMjYySDIzLjk3ODlDMjQuNTQyOCAxNC4yNjIgMjUgMTQuNzA1NCAyNSAxNS4yNTI0VjE1Ljc0NzZDMjUgMTYuMjk0NiAyNC41NDI4IDE2LjczOCAyMy45Nzg5IDE2LjczOEg5LjQ1OTM5WiIgZmlsbD0iIzJFMkUyRSIvPgo8L3N2Zz4K")
}

/* .menu__item span:after {
  content: '';
  height: 25px;
  width: 25px;
  background: url(${props => props.iconUrl}) no-repeat / 116px 125px;
  display: block;
  position: absolute;
  left: -35px;
  top: 50%;
  transform: translate(-50%, 0);
  border: 2px solid red;
} */
.section_wrapper,
.starting__page_wrapper,
.preferences__page_wrapper,
.terms__page_wrapper,
.contact__page_wrapper,
.favorite__absent,
.favorites__page_wrapper,
.recipe__page_wrapper,
.header__wrapper,
.welcome__page_wrapper
/* .recipe__section */
{
  opacity: 0;
  animation: fadeIn 1.5s forwards;
}




@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

/* @keyframes fadeDown {
  to {
    transform: translateY(0%);
  }
} */

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #fff;
}

.back-button__hidden {
  opacity: 0;
  pointer-events: none;
}
.button__back_wrapper{
  display: flex;
}

@media all and (max-width: 450px){
  .menu__nav .menu__item,
  .menu__nav .attempts__counter{
    padding: 5px 0 5px 20px;
  }
}
@media all and (max-width: 400px){
  
}
@media all and (max-width: 390px){
  
}
@media all and (max-width: 376px){
  
}
@media all and (max-width: 360px){
  
}


@media screen and (max-height: 500px) and (orientation: landscape) and (min-width: 500px) {
  .menu__title{
    margin-top: 1%;
  }  
}
@media screen and (max-height: 415px) and (orientation: landscape) and (min-width: 500px) {
  .menu__title{
    margin-top: 1%;
    margin-bottom: 0;
  }
  .menu__nav .menu__item, .menu__nav .attempts__counter{
    margin-top: 3px;
  }
  .menu__item span{
    font-size: 17px;
    line-height: 18px;
  }
  .menu__item_icon{
    height: 30px;
  }
}
@media screen and (max-height: 350px) and (orientation: landscape) and (min-width: 500px) {
  .menu__item_icon{
    height: 25px;
  }
}
@media screen and (max-height: 310px) and (orientation: landscape) and (min-width: 500px) {
  .menu__nav .menu__item, .menu__nav .attempts__counter{
    padding: 0px 0 0px 25%;
  }
}
