:root {
  --main-button-red: #F23161;
  --main-button-red-hover: #FF94AF;
  --main-button-red-active: #E74D73;
  --main-button-red-disabled: #D7D5CD;
  --background-color-all-pages: #ffffff !important;
  --welcome-text-color: #2a2a2a !important;
  --reset-button-active: #FFEDF2;
}

body {
background-color: var(--background-color-all-pages) !important;
color: var(--welcome-text-color) !important;
}

@media (prefers-color-scheme: dark) {
body {
  background-color: var(--background-color-all-pages) !important;
  color: var(--welcome-text-color) !important;
}
}