.section_wrapper {
  margin: 0 auto;
  max-width: 335px;
}

.autent__page_text {
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin-top: 10px;
}
.autent__page{
  padding-top: 10vh;
}
.autent__logo {
  width: 333px;
  height: 331px;
  margin: 0 auto;
}

.autent_number_wrapper {
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.autent__countrycode {
  position: relative;
  padding: 10px 35px 10px 10px;
  border: 1px solid #C3C1B9;
  border-radius: 12px;
}

.autent__countrycode:after {
  content: '';
  display: block;
  height: 16px;
  width: 16px;
  background: url(./img/usa.png) center/contain no-repeat;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

.autent__number {
  border: 1px solid #C3C1B9;
  border-radius: 12px;
  padding: 10px 15px;
  width: 100%;
  margin-left: 5px;
  cursor: pointer;
  outline: none;
  font-size: 16px;
  line-height: 16px;
}
.autent__number.error{
  border: 1px solid #E74D73;
}

.autent__page_button {
  display: block;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;
  border: none;
  border-radius: 100px;
  width: 100%;
  max-width: 240px;
  line-height: 60px;
  margin: 0 auto;
  background: var(--main-button-red);
  color: #fff;
  cursor: pointer;
  transition: all 0.4s;
}
/* .autent__page_button:active,
.autent__page_button:focus{
  background: var(--main-button-red-active);
} */
.autent__page_button:hover{
  background: var(--main-button-red-hover);
}

.hide {
  display: none;
}

.error__box{
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 12.61px;
  text-align: left;
  width: 100%;
  max-width: 330px;
  margin: 0 auto;
  color: #E74D73;
  height: 24px;
  margin-top: 5px;
  margin-bottom: 15px;

}
.error__box a{
  color: #E74D73;
  transition: color 0.3s;
}

.error__box a:hover,
.error__box a:active{
  color: #FF5580;
}

.error__box{
  text-align: center;
}

@media all and (max-width: 1280px) and (max-height: 600px){
  .autent__logo_wrapper{
      max-width: 270px;
      margin: 0 auto;
  }
  .autent__logo {
    width: 270px;
    height: 267px;
  }
  .autent__page_text{
      margin-top: 10px;
  }
  .autent__page_button{
      margin-top: 20px;
  }
}
@media all and (max-width: 768px){
  .autent__page{
    margin-top: 77px;
  }
  .autent__page_text{
    margin-top: 20px;
  }
  .autent_number_wrapper{
    margin-top: 16px;
  }
}
@media all and (max-width: 500px){
  
}
@media all and (max-width: 450px){
  .autent__page{
    padding-top: 76px;
    margin-top: 0;
  }
  .section_wrapper{
    max-width: calc(100% - 30px);
  }
  .autent__logo_wrapper,
  .autent__page_text,
  .autent_number_wrapper,
  .error__box{
    max-width: 333px;
    margin-left: auto;
    margin-right: auto;
  }
  .autent__logo {
    width: 100%;
    height: auto;
  }
}
@media all and (max-width: 415px){
  .autent__logo_wrapper,
  .autent__page_text,
  .autent_number_wrapper,
  .error__box{
    max-width: 333px;
  }
  
  .autent__page{
    padding-top: 30px;
    margin-top: 0;
  }
}
@media all and (max-width: 415px) and (max-height: 680px){
  /* .autent__logo_wrapper{
    max-width: 280px;
  } */

}
@media all and (max-width: 415px) and (max-height: 600px){
  .autent__page{
    margin-top: 0px;
  } 
}
@media all and (max-width: 400px){
  
}
@media all and (max-width: 390px){
  
}
@media all and (max-width: 376px){
  .autent__logo_wrapper{
    max-width: 330px;
  }
  
}

@media all and (max-width: 376px) and (max-height: 600px){
  .autent__page{
    padding-top: 10px;
  }
  .autent__page_button{
    margin-top: 0;
  }
  .autent__page_text{
    margin-top: 10px;
  }
}
@media all and (max-width: 376px) and (max-height: 580px){
  .autent__logo_wrapper{
      max-width: 292px;
  }
}
@media all and (max-width: 376px) and (max-height: 560px){
  .autent__logo_wrapper{
      max-width: 272px;
  }
}
@media all and (max-width: 360px){
  
}
@media all and (max-width: 350px){
  .autent__logo_wrapper{
    max-width: 200px;
  }
  .autent__logo {
    width: 200px;
    height: 197px;
  }
  .autent__page_text{
    max-width: 250px;
    font-size: 16px;
    line-height: 19px;
  }
  .autent__page_button{
    line-height: 50px;
    font-size: 20px;
  }
}
@media all and (max-width: 320px){
  
}

@media screen and (max-height: 500px) and (orientation: landscape) and (min-width: 500px) {
  .autent__logo_wrapper{
    display: none;
  }
  .autent__page{
    margin-top: 0;
  }
}
@media screen and (max-height: 350px) and (orientation: landscape) and (min-width: 500px) {
  .autent__page{
    padding: 0;
  }
}